
import React from 'react';
import Layout from '../components/Layout';

// @ts-ignore
import pic1 from '../assets/images/partner2.png';
// @ts-ignore
import pic2 from '../assets/images/partner3.png';
// @ts-ignore
import pic3 from '../assets/images/partner1.png';
// @ts-ignore
import pic4 from '../assets/images/partner4.png';
// @ts-ignore
import pic5 from '../assets/images/partner5.png';
// @ts-ignore
import pic6 from '../assets/images/partner6.png';

const IndexPage = () => (

  <Layout>
    <article id="main">
      <header>
        <h2>...TECHNOLOGY PARTNERS...</h2>
        <p>"Meet Our Technology Partners"</p>
      </header>

      <section className="wrapper style5">
        <div className="inner">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div id="card-outer">
                  <div className="card card1-top-border card1 card-block">
                    <div className="card-body">
                      <h3 className="card-title">Google Cloud</h3>
                      <p>We are partner with Google Cloud.</p>
                      <div className="card-inner-img">
                        <a href='https://cloud.google.com/'><img src={pic1} alt="cardImg" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div id="card-outer ">
                  <div className="card card2-top-border card2 card-block">
                    <div className="card-body">
                      <h3 className="card-title">Forcepoint</h3>
                      <p>We are partner with Forcepoint.</p>
                      <div className="card-inner-img">
                       <a href='https://www.forcepoint.com/'> <img src={pic3} alt="cardImg" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div id="card-outer">
                  <div className="card card3-top-border card3 card-block">
                    <div className="card-body">
                      <h3 className="card-title">VMWARE</h3>
                      <p>We are authorized partner with vmware.</p>
                      <div className="card-inner-img">
                        <a href='https://www.vmware.com/in.html'>
                          <img src={pic4} height="100" width="100" alt="cardImg" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className="col-md-4">
                <div id="card-outer">
                  <div className="card card4-top-border card4 card-block">
                    <div className="card-body">
                      <h3 className="card-title">Trend Micro</h3>
                      <p>We are partner with Trend Micro.</p>
                      <div className="card-inner-img">
                       <a href='https://www.trendmicro.com/en_in/business.html'><img src={pic2} alt="cardImg" /></a> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div id="card-outer">
                  <div className="card card5-top-border card5 card-block">
                    <div className="card-body">
                      <h3 className="card-title">DELL</h3>
                      <p>We are partner with DELL</p>
                      <div className="card-inner-img">
                       <a href='https://www.dell.com/en-in'> <img src={pic5} alt="cardImg" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               <div className="col-md-4">
                <div id="card-outer">
                  <div className="card card6-top-border card6 card-block">
                    <div className="card-body">
                      <h3 className="card-title">AWS</h3>
                      <p>We are partner with AWS</p>
                      <div className="card-inner-img">
                       <a href='https://aws.amazon.com/'><img src={pic6} alt="cardImg" /></a> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage
